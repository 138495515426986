@import 'bootstrap/scss/bootstrap-utilities';
@import "/node_modules/bootstrap/scss/bootstrap";

@include media-breakpoint-down(xl) {

    main {
        .left_menu {
            width: 256px;

            .my_profile {
                img {
                    width: 160px !important;
                }
            }

            .discard_txt {
                font-size: .9rem !important;
                text-overflow: ellipsis;
                width: 190px;
                display: inline-block;
            }
        }

        .dash_content {

            $cap-x-padding: 1rem;
            width: calc(100vw - 256px);

            .caption {
                padding-left: $cap-x-padding;
                padding-right: $cap-x-padding;

                h3 {
                    font-size: 1.6rem;
                }

                button {
                    font-size: 1rem;
                    padding: .4rem 2rem;
                }
            }

            .template_list {
                h4 {
                    font-size: 1.2rem;
                }
            }

            .dashboard_box {
                $width: 700;
                width: #{$width}px !important;
                height: #{($width * 1245 / 2985)}px !important;
            }
        }
        

    }

    .fixed-top {
        &.main_header {
            $padding-x: 1rem;

            .log {
                width: 220px;
            }

            .m_link {
                padding-left: $padding-x;
                padding-right: $padding-x;
                background-position: center 1.25rem !important;

                span {
                    display: none;
                }
            }

            .header_profile {
                img.dots {
                    margin-left: 1px;
                    margin-right: 0px;
                }
            }
        }
    }

    .dashboard_video {
        .closeBtn {
            right: -80px;
        }
    }

    .signup_wrap {


        .sign_card {
            width: 560px;

            &.vw_high {
                width: 800px;
            }
        }

        .ramp_card {
            width: 800px;

            &.card_xl {
                width: 810px;
            }

            .form-label {
                font-size: 1rem;
            }
        }
    }

    .action_box {
        right: 0px !important;
    }

}

@include media-breakpoint-down(lg) {
    main {
        .left_menu {
            .my_profile {
                img {
                    width: 120px !important;
                }
            }
        }

    }

    .my_effect {

        h5,
        p {
            font-size: .95rem;
        }

        .wd-200 {
            width: 140px;
        }
    }

    .gray_circle {
        width: 180px !important;
        height: 180px !important;
    }

    .fixed-top {
        .buttons {
            margin-right: 1rem !important;
        }
    }

    .dashboard_1 {
        .whiteboard {
            width: 100% !important;
            padding-left: 0px !important;
            background-size: contain !important;
            margin-left: 0 !important;

            h3 {
                font-size: 1.5rem !important;
                padding-left: 4rem;
            }

            p {
                padding-left: 4rem;
            }

            button {
                margin-left: 4rem;
            }
        }

    }

    .resp_line {
        width: 75%;
        display: inline-block;
    }

    .resp_line5 {
        width: 55%;
    }

    .signup_wrap {
        .sign_card {
            width: 560px;

            &.vw_high {
                width: 660px !important;
            }

        }

        .ramp_card {
            width: 700px;

            &.card_xl {
                width: 610px;
            }
        }

        .action_box {
            text-align: center;

            button {
                width: 5.5rem;
                font-size: .9rem;
            }
        }
    }

    .dash_content {
        width: calc(100vw - 236px) !important;

        &.one_column {
            width: calc(100vw - 80px) !important;
        }

        .dashboard_box {
            $width: 500;
            width: #{$width}px !important;
            height: #{($width * 1245 / 2985)}px !important;

            .details {
                margin-top: 100px !important;
            }
        }

        .back_btn {
            img {
                height: 30px;
            }

        }

        .box_shadow {
            padding: .2rem 1rem !important;
            font-size: .8rem !important;
        }

        .caption {
            .back_btn {
                font-size: .9rem !important;
                padding: .2rem 1.2rem;
                height: auto;

                img {
                    height: 17px;

                }
            }
        }
    }

    .fixed-bottom {
        .nav-item {
            font-size: 1.2rem !important;
            padding-left: .9rem !important;
            padding-right: .9rem !important;
        }
    }

    .profile_wrap {
        .profile_box {
            width: 723px !important;

            .profile_top_content {
                button {
                    left: 570px !important;
                }
            }
        }
    }

    .adapt_wrap {
        .dashboard_box {
            $width: 550;
            width: #{$width}px !important;
            height: #{($width * 1245 / 2985)}px !important;

            .details {
                margin-top: 100px !important;
            }
        }

        .right_wrap {
            width: calc(100% - 550px) !important;
        }
    }

}

@include media-breakpoint-down(xxl) {
    .dash_content {
        .dashboard_box {
            $width: 800;
            width: #{$width}px !important;
            height: #{($width * 1245 / 2985)}px !important;

            .details {
                margin-top: 210px !important;
            }
        }
    }
}

@include media-breakpoint-up(xl) {


    .adapt_content {
        width: 1200px !important;
    }

    .adapt_wrap {
        .right_wrap {
            width: 350px !important;
        }

        .left_wrap {
            width: calc(100% - 400px) !important;
        }
    }
}

@include media-breakpoint-down(md) {
    main{
        .left_menu{
            width: 220px;
        }
    }
    .signup_wrap {
        .sign_card {
            width: calc(100vw - 24px);

            &.vw_high {
                width: 800px;
            }

            .card-header {
                font-size: 1.5rem !important;
            }


        }

        p {
            font-family: 'Roboto Slab';
            display: block !important;
        }

        .ramp_card {
            width: calc(100vw - 24px);

            .title {
                padding-left: .5rem;


                .cell {
                    h4 {
                        font-size: 1.2rem;
                    }
                }
            }
            .name_box {
                width: auto !important;
            }

            .photo_box {
                .icon {
                    top: 5px;
                    right: 0px;
                }
            }

            &.card_xl {
                width: 810px;
            }

            .form-label {
                font-size: 1rem;
            }
        }
    }
    .edit_box{
        width: 100%;
    }

    
}